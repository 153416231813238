import React from "react";
import { graphql } from 'gatsby';
import Gallery from '@browniebroke/gatsby-image-gallery';

import Layout from "../templates/Layout";


export const pageQuery = graphql`
    query ImagesForGallery {
        allFile(
            filter: {
                relativeDirectory: {eq: "gallery/old"}
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        thumb: gatsbyImageData(
                            width: 270
                            height: 270
                            placeholder: BLURRED
                        )
                        full: gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`


class Photos extends React.Component {
    render() {
        const images = this.props.data.allFile.edges.map(({ node }) => node.childImageSharp)

        return (
            <Layout
                title="Gallery"
                location={this.props.location}
            >
                <Gallery images={images} />
            </Layout>
        )
    }
}

export default Photos